
import { Component, Vue, Watch, Prop, } from 'vue-property-decorator'
import OverlayBackground from "@/components/OverlayBackground/Index.vue"
import { ApplicationInterface, } from '@/store/types/Applications/Application'

@Component({
components:{OverlayBackground},
})
export default class CreateFolderDialogComponent extends Vue {
  @Prop({ required: true, }) readonly showDialog!: string
  @Prop({ required: true, }) readonly appSelected!: ApplicationInterface
  private dialog = false
  private confirmation = ''
  private loading = false

  @Watch('showDialog')
  show (val: boolean): void {
    if (val) {
      this.dialog = true
    } else {
      this.dialog = false
    }
  }
  @Watch('dialog')
  dialogClose (): void {
    if (this.dialog === false) {
      this.$emit('close')
    }
  }
  private resetVars () {
    this.confirmation = ''
  }

  private emitEvent () {
        this.loading = true

    if (this.confirmation === 'DESCONECTAR') {
      // this.$store.dispatch('ApplicationModule/deleteApp', this.appSelected.id).then(() => {
        this.$store.dispatch('NotificactionsModule/OpenNotification', {
          message: 'Éxito, Aplicacion Desconectada correctamente',
        })
            this.loading = false

        this.$emit('close')
      // })
    } else {
      this.$store.dispatch('NotificactionsModule/OpenNotification', {
        message: 'Error, La palabra clave no es la correcta para confirmar esta accion',
        color_notification: 'danger',
      })
          this.loading = false

      return
    }
  }
}
