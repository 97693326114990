
import { Component, Vue, } from 'vue-property-decorator'
import DisconnectDialog from '@/components/DisconnectConfirmation/Index.vue'

@Component({
  components: {
    DisconnectDialog,
  },
})
export default class ConfigurationComponent extends Vue {
  private applicationSelected = this.$store.state.ApplicationModule.ApplicationState.appSelected
  private showDialog = false

  private disconnect () {
    this.showDialog = true
  }

  private closeDialog () {
    this.$router.push('/startup/applications')
    this.showDialog = false
  }

  private goToEdit () {
    this.$router.push('/startup/applications/form/2')
  }
}
